<template>
  <div class="container-fluid mt-4">
    <a-page-header style="padding: 0" title="Users"> </a-page-header>

    <a-row justify="space-between" class="my-20">
      <a-col :sm="8" :xl="4">
        <a-input-search
          v-model:value="search"
          placeholder="Search with name/username/email"
          @input="searchInput"
          @pressEnter="() => {}"
          :loading="loading"
        />
      </a-col>
      <a-col :span="1">
        <a-button type="primary" @click="shownewModal = true">New</a-button>
      </a-col>
    </a-row>

    <!-- New user -->
    <a-modal
      v-model:visible="shownewModal"
      title="New User"
      :maskClosable="false"
      :keyboard="false"
      centered
      @ok="shownewModal = false"
    >
      <input type="password" hidden autocomplete="new-password" />
      <a-input v-model:value="newuser.name" placeholder="Name" class="mb-20" />
      <div class="mb-20">
        <a-input v-model:value="newuser.email" placeholder="Email" />
        <a-alert
          v-if="wrongNewEmail"
          message="Please enter a valid email"
          type="error"
          show-icon
        />
      </div>
      <div class="mb-20">
        <a-checkbox v-model:checked="newRoles.user">User</a-checkbox>
        <br />
        <a-checkbox v-model:checked="newRoles.manager">Manager</a-checkbox>
        <br />
        <a-checkbox v-model:checked="newRoles.admin">System Admin</a-checkbox>
      </div>
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="createNewUser"
          >Submit</a-button
        >
      </template>
    </a-modal>
    <!-- set status -->
    <a-modal
      v-model:visible="showStatusModal"
      title="Update status"
      :maskClosable="false"
      :keyboard="false"
      centered
    >
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="updateStatus(list[upateStatusI].id)"
          >Update</a-button
        >
      </template>

      <a-radio-group v-model:value="newStatus">
        <a-radio value="NORMAL">NORMAL</a-radio><br />
        <a-radio value="LOCKED">LOCKED</a-radio>
      </a-radio-group>
    </a-modal>
    <!-- set roles -->
    <a-modal
      v-model:visible="showroleModal"
      title="Set roles"
      :maskClosable="false"
      :keyboard="false"
      centered
      @ok="showroleModal = false"
    >
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="setRoles(list[setRolesI].id)"
          >Submit</a-button
        >
      </template>

      <a-checkbox v-model:checked="newRoles.user">User</a-checkbox>
      <br />
      <a-checkbox v-model:checked="newRoles.manager">Manager</a-checkbox>
      <br />
      <a-checkbox v-model:checked="newRoles.admin">System Admin</a-checkbox>
    </a-modal>
    <!--  salary modal  -->
    <a-modal
      v-model:visible="showsalaryModal"
      title="Salary"
      :maskClosable="false"
      :keyboard="false"
      centered
      destroyOnClose
      width="1500px"
      @ok="showsalaryModal = false"
    >
      <SalaryModal :userId="list[currentUser]?.id" />
    </a-modal>

    <!--  Employment modal  -->
    <a-modal
      v-model:visible="showEmploymentsModal"
      title="Employment"
      :maskClosable="false"
      :keyboard="false"
      destroyOnClose
      centered
      width="1500px"
    >
      <EmploymentsModal :userId="list[currentUser]?.id" />
      <template #footer>
        <a-button type="primary" @click="showEmploymentsModal = false">Ok</a-button>
      </template>
    </a-modal>

    <a-table
      :dataSource="list"
      :columns="columns"
      :loading="listloading"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: currentPage,
        defaultPageSize: limit,
      }"
      rowKey="id"
      @change="tableChange"
      class="ant-table-striped"
      :rowClassName="
        (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
      "
    >
      <template #name="{ text }"> {{ text }} </template>
      <template #username="{ text }"> {{ text }} </template>
      <template #email="{ text }">
        {{ text }}
      </template>
      <template #status="{ text }">
        {{ text }}
      </template>
      <template #roles="{ text }">
        <a-tag
          v-for="tag in text"
          :key="tag"
          :color="
            tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'
          "
        >
          {{ tag.toUpperCase() }}
        </a-tag>
      </template>
      <template #lastLoginTime="{ text }">
        {{ text ? formatTime(text) : "" }}
      </template>
      <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showStatusModal = true;
                    upateStatusI = text;
                    newStatus = list[text].status;
                  "
                  >Status</a
                >
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="openRolesModal(text)">Roles</a>
              </a-menu-item>

              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showEmploymentsModal = true;
                    currentUser = text;
                  "
                  >Employment</a
                >
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showsalaryModal = true;
                    editsalary = text;
                    currentUser = text;
                  "
                  >Salary</a
                >
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  createVNode,
  defineComponent,
  computed,
} from "vue";
import { useStore } from "vuex";
import {
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import * as dayjs from "dayjs";

import EmploymentsModal from "@/components/EmploymentsModal.vue";
import SalaryModal from "@/components/SalaryModal.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    ellipsis: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
    slots: { customRender: "name" },
  },
  {
    title: "Username",
    ellipsis: true,
    dataIndex: "username",
    key: "name",
  },
  {
    title: "Email",
    ellipsis: true,
    dataIndex: "email",
    key: "email",
  },

  {
    title: "Status",
    ellipsis: true,
    dataIndex: "status",
  },
  {
    title: "Roles",
    ellipsis: true,
    dataIndex: "roles",
    slots: { customRender: "roles" },
  },
  {
    title: "Last Login Time",
    ellipsis: true,
    dataIndex: "lastLoginTime",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "...",
    key: "tool",
    dataIndex: "key",
    slots: { customRender: "tool" },
  },
];

export default defineComponent({
  components: {
    SettingOutlined,
    EmploymentsModal,
    SalaryModal,
  },
  setup() {
    const { proxy } = getCurrentInstance();

    const store = useStore();
    const v = reactive({
      list: [],
      total: 0,
      page: 1,
      limit: 10,
      currentPage: 1,
      loading: false,
      listloading: false,
      search: "",

      shownewModal: false,
      wrongNewEmail: false,
      newuser: {
        name: "",
        email: "",
        roles: [],
      },

      showroleModal: false,
      setRolesI: "",
      newRoles: {
        admin: false,
        manager: false,
        user: true,
      },

      showStatusModal: false,
      newStatus: "NORMAL",
      upateStatusI: "",
      salaryerr: false,

      showsalaryModal: false,
      editsalary: null,

      activeKey: "name",

      showEmploymentsModal: false,
      currentUser: null,
    });

    async function getlist() {
      v.listloading = true;

      let obj = {
        page: v.page,
        limit: v.limit,
        searchText: v.search,
      };
      try {
        const res = await proxy.$axios.get("/api/v1/users", obj);
        if (res.status == 200) {
          v.total = res.data.total;
          v.list = res.data.items;
          v.list.forEach((item, index) => {
            item.key = index;
          });
        }
      } catch (err) {
        proxy.$msg.error(err);
      } finally {
        v.listloading = false;
      }
    }
    getlist();

    async function createNewUser() {
      let { name, username, email, pwd } = v.newuser;
      var reg =
        /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;

      if (!reg.test(email)) {
        v.wrongNewEmail = true;
        return;
      }

      let roles = [];
      Object.keys(v.newRoles).forEach(function (key) {
        if (v.newRoles[key]) {
          roles.push(key.toUpperCase());
        }
      });

      v.loading = true;
      try {
        const res = await proxy.$axios.post("api/v1/users", {
          name,
          email,
          roles,
        });
        if (res.status == 200) {
          proxy.$msg.success("Created successfully");
          getlist();
          v.shownewModal = false;
          v.newuser = {
            name: "",
            username: "",
            email: "",
            pwd: "",
            cpwd: "",
          };
        }
      } catch (err) {
        proxy.$msg.error("submit failed");
      } finally {
        v.loading = false;
      }
    }

    async function setRoles(id) {
      let roles = [];
      Object.keys(v.newRoles).forEach(function (key) {
        if (v.newRoles[key]) {
          roles.push(key.toUpperCase());
        }
      });

      const res = await proxy.$axios.put(`/api/v1/users/${id}/roles`, {
        roles,
      });
      if (res.status == 200) {
        v.showroleModal = false;
        proxy.$msg.success("update role success");
        getlist();
      }
    }

    function showDeleteConfirm(index) {
      const user = v.list[index];
      Modal.confirm({
        title: () => `Are you sure delete this user ${user.name}?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",

        onOk() {
          deleteuser(index);
        },
        // onCancel() {
        //   console.log("Cancel");
        // },
      });
    }

    async function deleteuser(i) {
      const res = await proxy.$axios.delete(`/api/v1/users/${v.list[i].id}`);
      if (res.status == 200) {
        getlist();
        proxy.$msg.success("delete success");
      }
    }

    async function updateStatus(id) {
      v.loading = true;
      try {
        const res = await proxy.$axios.put(`/api/v1/users/${id}/status`, {
          status: v.newStatus,
        });
        if (res.status == 200) {
          proxy.$msg.success("update success");
          v.showStatusModal = false;
          v.list[v.upateStatusI].status = v.newStatus;
        }
      } catch (err) {
        proxy.$msg.error(err);
      } finally {
        v.loading = false;
      }
    }

    function openRolesModal(i) {
      v.newRoles = {
        admin: v.list[i].roles.some((item) => item == "ADMIN"),
        manager: v.list[i].roles.some((item) => item == "MANAGER"),
        user: v.list[i].roles.some((item) => item == "USER"),
      };
      v.showroleModal = true;
      v.setRolesI = i;
    }

    async function getsalary(i) {
      v.loading = true;
      const res = await proxy.$axios.get(
        `/api/v1/users/${v.list[i].id}/salary_settings`
      );
      v.loading = false;
      if (res.data) {
        let {
          total,
          socialSecurityCompanyPart,
          socialSecurityEmployeePart,
          personalIncomeTax,
          remarks,
        } = res.data;
        v.newsalary = {
          total: total / 100,
          socialSecurityCompanyPart: socialSecurityCompanyPart / 100,
          socialSecurityEmployeePart: socialSecurityEmployeePart / 100,
          personalIncomeTax: personalIncomeTax / 100,
          remarks,
        };
      }
    }

    function salaryinput(e) {
      let value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      switch (e.target.id) {
        case "salary1":
          v.newsalary.total = value;
          break;
        case "salary2":
          v.newsalary.socialSecurityEmployeePart = value;
          break;
        case "salary3":
          v.newsalary.socialSecurityCompanyPart = value;
          break;
        case "salary4":
          v.newsalary.personalIncomeTax = value;
          break;
      }
    }

    const salaryresult = computed(() => {
      return (
        v.newsalary.total -
        v.newsalary.socialSecurityEmployeePart -
        v.newsalary.personalIncomeTax
      ).toFixed(2);
    });

    watch(
      () => v.newsalary,
      (val, oldval) => {
        let total = Number(val.total);
        if (Number(val.socialSecurityEmployeePart) > total) {
          v.salaryerr = true;
          return;
        }
        if (Number(val.socialSecurityEmployeePart) > total) {
          v.salaryerr = true;
          return;
        }
        if (Number(val.personalIncomeTax) > total) {
          v.salaryerr = true;
          return;
        }
        v.salaryerr = false;
      },
      { deep: true }
    );

    function onSearch() {
      v.page = 1;
      v.currentPage = 1;
      getlist();
    }

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
      getlist();
    }

    let timeholder;
    function searchInput() {
      let timeout = 600;
      if (timeholder) {
        clearTimeout(timeholder);
      }
      timeholder = setTimeout(() => {
        clearTimeout(timeholder);
        onSearch();
      }, timeout);
    }

    function formatTime(dateStr) {
      return dayjs(dateStr).format("YYYY/MM/DD HH:mm:ss Z");
    }

    return {
      ...toRefs(v),
      columns,
      tableChange,
      createNewUser,
      setRoles,
      showDeleteConfirm,
      updateStatus,
      getsalary,

      salaryresult,
      salaryinput,
      openRolesModal,
      onSearch,
      searchInput,
      formatTime,
    };
  },
});
</script>
<style lang="scss" scoped>
.label {
  color: #666666;
  font-size: 16px;
  font-weight: 550;
}
.ant-table-striped :deep(.table-striped) td {
  filter: opacity(50%);
}
</style>

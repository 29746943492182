<template>
  <div>
    <a-row style="margin-bottom: 10px" justify="end">
      <a-col>
        <a-button @click="visible = true">Create</a-button>
      </a-col>
    </a-row>

    <a-table
      :dataSource="list"
      :columns="columns"
      :loading="listloading"
      rowKey="id"
      :pagination="false"
      class="ant-table-striped"
    >
      <template #entryDate="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD") : "" }}
      </template>
      <template #exitDate="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD") : "" }}
      </template>
      <template #createTime="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD HH:mm:ss") : "" }}
      </template>
      <template #updateTime="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD HH:mm:ss") : "" }}
      </template>
      <template #current="{ text }">
        {{ text ? "✔" : "" }}
      </template>
      <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="handleEdit(text)">Edit</a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal v-model:visible="visible" destroyOnClose title="Create Salary">
      <a-form layout="vertical">
        <!-- <label class="" title="user">User</label> -->
        <!-- <a-typography-title :level="4">{{
          list[editsalary].fullname
        }}</a-typography-title> -->

        <a-form-item label="Total Before Tax 税前月薪">
          <a-input
            v-model:value="newsalary.total"
            @change="salaryinput"
            id="salary1"
          />
        </a-form-item>
        <a-form-item label="Social Security (Personal part) 社保个人部分">
          <a-input
            v-model:value="newsalary.socialSecurityEmployeePart"
            id="salary2"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              newsalary.socialSecurityEmployeePart < 0 ||
              Number(newsalary.socialSecurityEmployeePart) >
                Number(newsalary.total)
            "
            message="Social Security cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>
        <a-form-item label="Social Security (Company part) 社保公司部分">
          <a-input
            v-model:value="newsalary.socialSecurityCompanyPart"
            id="salary3"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              newsalary.socialSecurityCompanyPart < 0 ||
              Number(newsalary.socialSecurityCompanyPart) >
                Number(newsalary.total)
            "
            message="Social Security cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>
        <a-form-item label="Personal Income Tax 个人所得税">
          <a-input
            v-model:value="newsalary.personalIncomeTax"
            id="salary4"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              newsalary.personalIncomeTax < 0 ||
              Number(newsalary.personalIncomeTax) > Number(newsalary.total)
            "
            message="Individual Income Tax cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>

        <a-form-item label="Remarks 备注">
          <a-textarea :rows="4" v-model:value="newsalary.remarks" />
        </a-form-item>
        <label class="" title="total">Total After Tax 税后月薪</label>
        <a-typography-title :level="4">{{ salaryresult }}</a-typography-title>
        <a-form-item>
          <a-checkbox v-model:checked="newsalary.current">Current</a-checkbox>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          :disabled="salaryerr"
          @click="setsalary"
          >Submit</a-button
        >
      </template>
    </a-modal>

    <a-modal
      v-model:visible="showEditModal"
      title="Update Salary"
      destroyOnClose
      @ok="updateEmployments()"
    >
      <a-form layout="vertical">
        <!-- <label class="" title="user">User</label> -->
        <!-- <a-typography-title :level="4">{{
          list[editsalary].fullname
        }}</a-typography-title> -->

        <a-form-item label="Total Before Tax 税前月薪">
          <a-input
            v-model:value="tobeEdit.total"
            @change="salaryinput"
            id="salary1"
          />
        </a-form-item>
        <a-form-item label="Social Security (Personal part) 社保个人部分">
          <a-input
            v-model:value="tobeEdit.socialSecurityEmployeePart"
            id="salary2"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              tobeEdit.socialSecurityEmployeePart < 0 ||
              Number(tobeEdit.socialSecurityEmployeePart) >
                Number(tobeEdit.total)
            "
            message="Social Security cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>
        <a-form-item label="Social Security (Company part) 社保公司部分">
          <a-input
            v-model:value="tobeEdit.socialSecurityCompanyPart"
            id="salary3"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              tobeEdit.socialSecurityCompanyPart < 0 ||
              Number(tobeEdit.socialSecurityCompanyPart) >
                Number(tobeEdit.total)
            "
            message="Social Security cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>
        <a-form-item label="Personal Income Tax 个人所得税">
          <a-input
            v-model:value="tobeEdit.personalIncomeTax"
            id="salary4"
            @change="salaryinput"
          />
          <a-alert
            v-if="
              tobeEdit.personalIncomeTax < 0 ||
              Number(tobeEdit.personalIncomeTax) > Number(tobeEdit.total)
            "
            message="Individual Income Tax cannot be negative or greater than total"
            type="error"
            show-icon
          />
        </a-form-item>

        <a-form-item label="Remarks 备注">
          <a-textarea :rows="4" v-model:value="tobeEdit.remarks" />
        </a-form-item>
        <label class="" title="total">Total After Tax 税后月薪</label>
        <a-typography-title :level="4">{{
          editSalaryresult
        }}</a-typography-title>
        <a-form-item>
          <a-checkbox v-model:checked="tobeEdit.current">Current</a-checkbox>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="updateEmployments"
          >Submit</a-button
        >
      </template>
    </a-modal>
  </div>
</template>



<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    slots: { customRender: "id" },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "entryDate",
    slots: { customRender: "total" },
  },
  {
    title: "Social Security Company Part",
    dataIndex: "socialSecurityCompanyPart",
    key: "socialSecurityCompanyPart",
    slots: { customRender: "socialSecurityCompanyPart" },
  },

  {
    title: "Social Security Employee Part",
    dataIndex: "socialSecurityEmployeePart",
    slots: { customRender: "socialSecurityEmployeePart" },
  },
  {
    title: "Personal Income Tax",
    dataIndex: "personalIncomeTax",
    slots: { customRender: "personalIncomeTax" },
  },
  {
    title: "Actual Payoff",
    dataIndex: "actualPayoff",
  },

  {
    title: "Create Time",
    dataIndex: "createTime",
    slots: { customRender: "createTime" },
  },
  {
    title: "Update Time",
    dataIndex: "updateTime",
    slots: { customRender: "updateTime" },
  },
  {
    title: "Current",
    dataIndex: "current",
    slots: { customRender: "current" },
  },

  {
    title: "...",
    key: "tool",
    dataIndex: "key",
    slots: { customRender: "tool" },
  },
];
import {
  reactive,
  toRefs,
  getCurrentInstance,
  createVNode,
  watch,
  computed,
} from "vue";
import * as dayjs from "dayjs";
import { Modal } from "ant-design-vue";
import {
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

export default {
  props: ["userId"],
  components: {
    SettingOutlined,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();

    const v = reactive({
      list: [],
      listloading: false,
      visible: false,
      entryDate: null,
      exitDate: null,
      current: false,
      editing: false,

      showEditModal: false,
      tobeEdit: {},

      newsalary: {
        total: null,
        socialSecurityCompanyPart: null,
        socialSecurityEmployeePart: null,
        personalIncomeTax: null,
        remarks: "",
        current: false,
      },
      salaryerr: false,
      loading: false,
    });

    watch(
      () => v.newsalary,
      (val, oldval) => {
        let total = Number(val.total);
        if (Number(val.socialSecurityEmployeePart) > total) {
          v.salaryerr = true;
          return;
        }
        if (Number(val.socialSecurityEmployeePart) > total) {
          v.salaryerr = true;
          return;
        }
        if (Number(val.personalIncomeTax) > total) {
          v.salaryerr = true;
          return;
        }
        v.salaryerr = false;
      },
      { deep: true }
    );

    async function getList() {
      try {
        v.listloading = true;
        const res = await proxy.$axios.get(
          `/api/v1/users/${props.userId}/salary_settings`
        );

        if (res.data) {
          v.list = res.data;
          v.list.forEach((item, index) => {
            item.total = item.total / 100;
            item.actualPayoff = item.actualPayoff / 100;
            item.socialSecurityCompanyPart =
              item.socialSecurityCompanyPart / 100;
            item.socialSecurityEmployeePart =
              item.socialSecurityEmployeePart / 100;
            item.personalIncomeTax = item.personalIncomeTax / 100;
            item.key = index;
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        v.listloading = false;
      }
    }
    getList();

    async function setsalary(id) {
      let {
        total,
        socialSecurityCompanyPart,
        socialSecurityEmployeePart,
        personalIncomeTax,
        remarks,
        current,
      } = v.newsalary;
      v.loading = true;

      try {
        const res = await proxy.$axios.post(
          `api/v1/users/${props.userId}/salary_settings`,
          {
            total: proxy.$filters.accMul(total, 100),
            socialSecurityCompanyPart: proxy.$filters.accMul(
              socialSecurityCompanyPart,
              100
            ),
            socialSecurityEmployeePart: proxy.$filters.accMul(
              socialSecurityEmployeePart,
              100
            ),
            personalIncomeTax: proxy.$filters.accMul(personalIncomeTax, 100),
            remarks,
            current,
          }
        );
        if (res.status == 200) {
          getList();
          v.visible = false;
          proxy.$msg.success("created success");
        }
      } catch (err) {
        proxy.$msg.error(err);
      } finally {
        v.loading = false;
      }
    }

    function handleEdit(index) {
      let {
        total,
        socialSecurityCompanyPart,
        socialSecurityEmployeePart,
        personalIncomeTax,
        remarks,
        current,
        id,
      } = v.list[index];
      v.tobeEdit = {
        total,
        socialSecurityCompanyPart,
        socialSecurityEmployeePart,
        personalIncomeTax,
        remarks,
        current,
        id,
      };
      v.showEditModal = true;
    }

    async function updateEmployments() {
      let {
        total,
        socialSecurityCompanyPart,
        socialSecurityEmployeePart,
        personalIncomeTax,
        remarks,
        current,
        id,
      } = v.tobeEdit;
      v.loading = true;

      try {
        const res = await proxy.$axios.put(
          `api/v1/users/${props.userId}/salary_settings/${id}`,
          {
            total: proxy.$filters.accMul(total, 100),
            socialSecurityCompanyPart: proxy.$filters.accMul(
              socialSecurityCompanyPart,
              100
            ),
            socialSecurityEmployeePart: proxy.$filters.accMul(
              socialSecurityEmployeePart,
              100
            ),
            personalIncomeTax: proxy.$filters.accMul(personalIncomeTax, 100),
            remarks,
            current,
          }
        );
        if (res.status == 200) {
          getList();
          v.showEditModal = false;
          proxy.$msg.success("update success");
        }
      } catch (err) {
        proxy.$msg.error(err);
      } finally {
        v.loading = false;
      }
    }

    function showDeleteConfirm(index) {
      const user = v.list[index];
      Modal.confirm({
        title: () => `Are you sure delete this salary (id: ${user.id})?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",

        onOk() {
          deleteuser(index);
        },
        // onCancel() {
        //   console.log("Cancel");
        // },
      });
    }

    async function deleteuser(i) {
      const res = await proxy.$axios.delete(
        `/api/v1/users/${props.userId}/salary_settings/${v.list[i].id}`
      );
      if (res.status == 200) {
        getList();
        proxy.$msg.success("delete success");
      }
    }

    function formatTime(dateStr, str) {
      return dayjs(dateStr + "").format(str);
    }

    const salaryresult = computed(() => {
      return (
        v.newsalary.total -
        v.newsalary.socialSecurityEmployeePart -
        v.newsalary.personalIncomeTax
      ).toFixed(2);
    });

    const editSalaryresult = computed(() => {
      if (v.showEditModal)
        return (
          v.tobeEdit.total -
          v.tobeEdit.socialSecurityEmployeePart -
          v.tobeEdit.personalIncomeTax
        ).toFixed(2);
    });

    function salaryinput(e) {
      let value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      switch (e.target.id) {
        case "salary1":
          v.newsalary.total = value;
          break;
        case "salary2":
          v.newsalary.socialSecurityEmployeePart = value;
          break;
        case "salary3":
          v.newsalary.socialSecurityCompanyPart = value;
          break;
        case "salary4":
          v.newsalary.personalIncomeTax = value;
          break;
      }
    }

    return {
      ...toRefs(v),

      columns,
      formatTime,
      showDeleteConfirm,
      updateEmployments,
      handleEdit,
      setsalary,
      salaryresult,
      salaryinput,
      editSalaryresult,
    };
  },
};
</script>

<style>
</style>



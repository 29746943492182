<template>
  <div>
    <a-row style="margin-bottom: 10px" justify="end">
      <a-col>
        <a-button @click="visible = true">Create</a-button>
      </a-col>
    </a-row>

    <a-table
      :dataSource="list"
      :columns="columns"
      :loading="listloading"
      rowKey="id"
      :pagination="false"
      class="ant-table-striped"
    >
      <template #entryDate="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD") : "" }}
      </template>
      <template #exitDate="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD") : "" }}
      </template>
      <template #socialSecurityStart="{ text }">
        {{ text ? formatTime(text, "YYYY/MM") : "" }}
      </template>
      <template #socialSecurityStop="{ text }">
        {{ text ? formatTime(text, "YYYY/MM") : "" }}
      </template>
      <template #createTime="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD HH:mm:ss") : "" }}
      </template>
      <template #updateTime="{ text }">
        {{ text ? formatTime(text, "YYYY/MM/DD HH:mm:ss") : "" }}
      </template>
      <template #current="{ text }">
        {{ text ? "✔" : "" }}
      </template>
      <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a href="javascript:;" @click="handleEdit(text)">Edit</a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      v-model:visible="visible"
      destroyOnClose
      title="Create Employments"
      @ok="createEmployments"
    >
      <a-space direction="vertical" :size="12">
        Entry date:
        <a-date-picker v-model:value="entryDate" />
        Exit date(Optional)
        <a-date-picker v-model:value="exitDate" />
        Social security start(Optional)
        <a-month-picker v-model:value="socialSecurityStart" />
        Social security stop(Optional)
        <a-month-picker v-model:value="socialSecurityStop" />

        <a-checkbox v-model:checked="current">Current</a-checkbox>
      </a-space>
    </a-modal>

    <a-modal
      v-model:visible="showEditModal"
      title="Update Employments"
      destroyOnClose
      @ok="updateEmployments()"
    >
      <a-space direction="vertical" :size="12">
        Entry date:
        <a-date-picker v-model:value="tobeEdit.entryDate" />
        Exit date(Optional)
        <a-date-picker v-model:value="tobeEdit.exitDate" />
        Social security start(Optional)
        <a-month-picker v-model:value="tobeEdit.socialSecurityStart" />
        Social security stop(Optional)
        <a-month-picker v-model:value="tobeEdit.socialSecurityStop" />

        <a-checkbox v-model:checked="tobeEdit.current">Current</a-checkbox>
      </a-space>
    </a-modal>
  </div>
</template>



<script>
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    slots: { customRender: "id" },
  },
  {
    title: "Entry Date",
    dataIndex: "entryDate",
    key: "entryDate",
    slots: { customRender: "entryDate" },
  },
  {
    title: "Exit date",
    dataIndex: "exitDate",
    key: "exitDate",
    slots: { customRender: "exitDate" },
  },
  {
    title: "Social Security Start",
    dataIndex: "socialSecurityStart",
    slots: { customRender: "socialSecurityStart" },
  },
  {
    title: "Social Security Stop",
    dataIndex: "socialSecurityStop",
    slots: { customRender: "socialSecurityStop" },
  },

  {
    title: "Create Time",
    dataIndex: "createTime",
    slots: { customRender: "createTime" },
  },
  {
    title: "Update Time",
    dataIndex: "updateTime",
    slots: { customRender: "updateTime" },
  },
  {
    title: "Current",
    dataIndex: "current",
    slots: { customRender: "current" },
  },

  {
    title: "...",
    key: "tool",
    dataIndex: "key",
    slots: { customRender: "tool" },
  },
];
import { reactive, toRefs, getCurrentInstance, createVNode } from "vue";
import * as dayjs from "dayjs";
import { Modal } from "ant-design-vue";
import {
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";

export default {
  props: ["userId"],
  components: {
    SettingOutlined,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();

    const v = reactive({
      list: [],
      listloading: false,
      visible: false,
      entryDate: null,
      exitDate: null,
      current: false,
      socialSecurityStart: null,
      socialSecurityStop: null,

      editing: false,

      showEditModal: false,
      tobeEdit: {},
    });

    async function getList() {
      try {
        v.listloading = true;
        const res = await proxy.$axios.get(
          `/api/v1/users/${props.userId}/employments`
        );

        if (res.data) {
          v.list = res.data;
          v.list.forEach((item, index) => {
            item.entryDate += "";
            if (item.exitDate) item.exitDate += "";
            if (item.socialSecurityStart) item.socialSecurityStart += "";
            if (item.socialSecurityStop) item.socialSecurityStop += "";

            item.key = index;
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        v.listloading = false;
      }
    }
    getList();

    async function createEmployments(params) {
      let {
        exitDate,
        entryDate,
        current,
        socialSecurityStop,
        socialSecurityStart,
      } = v;

      if (!entryDate) {
        proxy.$msg.error("pleace select entry date");
        return;
      }

      if (typeof entryDate == "string") {
        entryDate = Number(entryDate);
      }

      if (typeof exitDate == "string") {
        exitDate = Number(exitDate);
      }

      if (typeof socialSecurityStart == "string") {
        socialSecurityStart = Number(socialSecurityStart);
      }

      if (typeof socialSecurityStop == "string") {
        socialSecurityStop = Number(socialSecurityStop);
      }

      if (entryDate?.format) {
        entryDate = Number(entryDate.format("YYYYMMDD"));
      }
      if (exitDate?.format) {
        exitDate = Number(exitDate.format("YYYYMMDD"));
      }
      if (socialSecurityStart?.format)
        socialSecurityStart = Number(socialSecurityStart.format("YYYYMM"));
      if (socialSecurityStop?.format)
        socialSecurityStop = Number(socialSecurityStop.format("YYYYMM"));

      try {
        const res = await proxy.$axios.post(
          `/api/v1/users/${props.userId}/employments`,
          {
            exitDate,
            entryDate,
            socialSecurityStop,
            socialSecurityStart,
            current,
          }
        );
        if (res.data) {
          proxy.$msg.success("Created successfully");
          v.visible = false;
          v.exitDate = null;
          v.entryDate = null;
          v.current = false;
          getList();
        }
      } catch (err) {
        console.log(err);
      }
    }

    function handleEdit(index) {
      let {
        exitDate,
        entryDate,
        current,
        id,
        socialSecurityStart,
        socialSecurityStop,
      } = v.list[index];
      v.tobeEdit = {
        exitDate,
        entryDate,
        current,
        socialSecurityStart,
        socialSecurityStop,
        id,
      };
      v.showEditModal = true;
    }

    async function updateEmployments(id) {
      let {
        exitDate,
        entryDate,
        socialSecurityStart,
        socialSecurityStop,
        current,
      } = v.tobeEdit;

      if (!entryDate) {
        proxy.$msg.error("pleace select entry date");
        return;
      }

      if (typeof entryDate == "string") {
        entryDate = Number(entryDate);
      }

      if (typeof exitDate == "string") {
        exitDate = Number(exitDate);
      }

      if (typeof socialSecurityStart == "string") {
        socialSecurityStart = Number(socialSecurityStart);
      }

      if (typeof socialSecurityStop == "string") {
        socialSecurityStop = Number(socialSecurityStop);
      }

      if (exitDate?.format) {
        exitDate = Number(exitDate.format("YYYYMMDD"));
      }
      if (entryDate?.format) entryDate = Number(entryDate.format("YYYYMMDD"));
      if (socialSecurityStop?.format)
        socialSecurityStop = Number(socialSecurityStop.format("YYYYMM"));
      if (socialSecurityStart?.format)
        socialSecurityStart = Number(socialSecurityStart.format("YYYYMM"));

      try {
        const res = await proxy.$axios.put(
          `/api/v1/users/${props.userId}/employments/${v.tobeEdit.id}`,
          {
            exitDate,
            entryDate,
            socialSecurityStart,
            socialSecurityStop,
            current,
          }
        );

        if (res.status == 200) {
          proxy.$msg.success("Updata successfully");
          getList();
          v.showEditModal = false;
        }
      } catch (err) {
        proxy.$msg.error("Network error");

        console.log(err);
      } finally {
        v.editing = false;
      }
    }

    function showDeleteConfirm(index) {
      const user = v.list[index];
      Modal.confirm({
        title: () => `Are you sure delete this employment (id: ${user.id})?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",

        onOk() {
          deleteuser(index);
        },
        // onCancel() {
        //   console.log("Cancel");
        // },
      });
    }

    async function deleteuser(i) {
      const res = await proxy.$axios.delete(
        `/api/v1/users/${props.userId}/employments/${v.list[i].id}`
      );
      if (res.status == 200) {
        getList();
        proxy.$msg.success("delete success");
      }
    }

    function formatTime(dateStr, str) {
      return dayjs(dateStr + "").format(str);
    }

    return {
      ...toRefs(v),
      createEmployments,
      columns,
      formatTime,
      showDeleteConfirm,
      updateEmployments,
      handleEdit,
    };
  },
};
</script>

<style>
</style>